.Grid {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.GridBody {
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}
