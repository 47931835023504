@import "./reset.scss";
@import "./variables.scss";

html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: $background-color;
  font-size: 14px;
  font-family: "Fira Sans";
  color: $stdtext-color;
}

::selection {
  color: white;
  background: #6f08cd; /* WebKit/Blink Browsers */
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.ag-theme-material .ag-header {
  border-bottom-width: 2px;
  border-bottom-color: #dad4f3;
  font-size: 12px;
}

.ag-header {
  border-radius: 10px 10px 0 0;
}

.ag-theme-material {
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.ag-filter-select,
.ag-filter-filter {
  height: 30px;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
}

.ag-theme-material .ag-row-selected {
  background-color: rgb(211, 195, 247) !important;
}

.ag-theme-material .ag-icon-checkbox-checked {
  color: $theme-color !important;
}

.react-datepicker-wrapper {
  width: auto;
}
