@import '../../variables.scss';

.Aside {
  position: fixed;
  top: 0;
  left: 0;
  width: $homeaside-width;
  bottom: 0;
  background-color: #7C0AC2;
  z-index: 10;
  box-sizing: border-box;
  box-shadow: 5px 0 40px rgba(0, 0, 0, 0.15);
  transition: all 200ms ease-out;
  &.Collapse {
    width: $homeaside-collapsed-width;
  }
}

.NavWrapper {
  position: absolute;
  top: $homeheader-height;
  left: 0;
  right: 0;
  bottom: $homeaside-collapsebtn-height;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0px;
  justify-content: center;
}

.CollapseBtn {
  height: $homeaside-collapsebtn-height;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  user-select: none;
  cursor: pointer;
  transition: all 200ms ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: $theme-color-dark;
  color: #fff;
  &:focus {
    outline: none !important;
  }
}

.LogoBox {
  height: $homeheader-height;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 28px;
  }
}

.SubLogo {
  width: 100px;
  display: flex;
  align-items: center;
}

.Open {
  margin-top: 26px;
}