@import './variables.scss';

.HomeLayout {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.HomeLayoutContent {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: $homeaside-collapsed-width;
  bottom: 0;
  right: 0;
  transition: all 200ms ease-out;
  overflow-y: auto;
  &.Open {
    left: $homeaside-width;
  }
}
