//Gestion des couleurs
$color-first: #8700FF;
$color-first-opacity: rgba($color-first, 0.06);

$color-second: #2D235A;
$color-second-opacity: rgba($color-second, 0.06);

$color-third: #8278AA;
$color-third-dark: #640CB6;
$color-third-darker: #370F6E;

$color-status-red: #EE4266;
$color-status-green: #2EC4B6;
$color-status-yellow: #ECA400;
$color-status-red-hover: #b43562;
$color-status-green-hover: #29939b;
$color-status-yellow-hover: #b27d14;

$color-gray-darker: #3C3C55;
$color-gray-dark: #57576B;
$color-gray: #78738C;
$color-gray-light: #BEBED2;
$color-gray-lighter: #DCDCE1;

$background-color: #F2F1F6;
$theme-color: #6F08CD;
$theme-color-dark: #380F6F;
$stdtext-color: #999;
$std-border: 1px solid #d5d5d5;
$heading-color: #2d235b;

//Gestion des box-shadow
$box-shadow-default: 0px 0px 12px 0px rgba(#000000, 0.05);
$box-shadow-default-active: 0px 0px 14px 2px rgba(#000000, 0.12);
$box-shadow-black-menu: 0px 0px 10px 0px rgba(#000000, 0.05);
$box-shadow-black-badge: 0px 0px 8px 0px rgba(#000000, 0.30);

//Gestion des fonts
$font-family: 'Fira Sans', sans-serif!default;
$font-family-icons: 'Font Awesome 5 Pro';
$font-size-general: 1.5rem;
$line-height-general: 2.4rem;

$title-h1-font-size: 6.4rem;
$title-h1-line-height: 9.6rem;
$title-h1-font-weight: 600;
$title-h1-margin-bottom: 0;
$title-h1-margin-top: 0;
$title-h1-color: $color-first;

$title-h2-font-size: 5.6rem;
$title-h2-line-height: 8.8rem;
$title-h2-font-weight: 600;
$title-h2-margin-bottom: 0;
$title-h2-margin-top: 0;
$title-h2-color: $color-first;

$title-h3-font-size: 4.8rem;
$title-h3-line-height: 8rem;
$title-h3-font-weight: 500;
$title-h3-margin-bottom: 0;
$title-h3-margin-top: 0;
$title-h3-color: $color-first;

$title-h4-font-size: 4rem;
$title-h4-line-height: 7.2rem;
$title-h4-font-weight: 500;
$title-h4-margin-bottom: 0;
$title-h4-margin-top: 0;
$title-h4-color: $color-first;

$title-h5-font-size: 3.2rem;
$title-h5-line-height: 6.4rem;
$title-h5-font-weight: 500;
$title-h5-margin-bottom: 0;
$title-h5-margin-top: 0;
$title-h5-color: $color-first;

$title-h6-font-size: 2.4rem;
$title-h6-line-height: 5.6rem;
$title-h6-font-weight: 300;
$title-h6-margin-bottom: 0;
$title-h6-margin-top: 3.2rem;
$title-h6-color: $color-second;

$paragraph-font-weight: 300;
$paragraph-margin-bottom: 3.2rem;


$homeheader-height: 84px;
$homeaside-width: 240px;
$homesubpanel-height: 150px;
$homeaside-collapsed-width: 90px;
$homepanel-verticalshadow: 0 2px 15px rgba(0, 0, 0, .1);
$homepanel-verticalreverseshadow: 0 -2px -15px rgba(0, 0, 0, .1);
$homepanel-horizontalshadow: 2px 0 15px rgba(0, 0, 0, .1);
$homeaside-collapsebtn-height: 100px;
$homesubpanel-background-color: #F9FAFB;
$homedrawer-width: 500px;
$homedrawer-shadow: -3px 0 30px rgba(0, 0, 0, .15);
$homecontextpanel-height: 50px;

$dash-accent: #E9E8F0;
$dash-text: #26213C;
$font: 'Fira Sans', ;