@import '../../variables.scss';

$button-color: #ffffff;
$button-background: transparent;
$button-font-weight: 400;
$button-font-size: 1.4rem;
$button-border-radius: 100px;
$button-font-size-icon: 1.6rem;

$button-padding: 1rem 2.4rem;
$button-padding-top: 1.2rem;
$button-padding-top-icon-left: 1rem;
$button-padding-top-icon-right: 1rem;

$button-padding-left: 2.4rem;
$button-padding-left-icon-left: 2rem;
$button-padding-left-icon-right:2rem;

$button-padding-bottom: 1rem;
$button-padding-bottom-icon-left: 1rem;
$button-padding-bottom-icon-right: 1rem;

$button-padding-right: 2.4rem;
$button-padding-right-icon-left:2rem;
$button-padding-right-icon-right: 2rem;

$button-small-padding: 0.8rem 1.6rem;
$button-small-padding-top: 1.4rem;
$button-small-padding-left: 3rem;
$button-small-padding-bottom: 1.4rem;
$button-small-padding-right: 3rem;
$button-small-font-size: 1.3rem;

a:link, a:active, a:hover, a:visited {
  text-decoration: none;
  color: $theme-color;
}
a:active {
  font-weight: 500; //"Semi-Bold"
}

.GrpButton {
  // height: 52px;
  align-items: center;
  border-radius: $button-border-radius;
  border: 1px hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.5px;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: $button-padding-bottom;
  padding-left: $button-padding-left;
  padding-right: $button-padding-right;
  padding-top: $button-padding-top;
  transition: all 300ms ease-out;
  user-select: none;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  &:hover {
    background-color: $background-color;
    transition: (0.2s);
  }
  &:focus { 
    outline:none;
  }
  letter-spacing: 1.25px;
  text-align: center;
}
.IconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $button-font-size-icon;
}

// Type Variables
.Primary {
  background-color: $color-first;
  font-family: 'Fira Sans', sans-serif;
  border-style: solid;
  // border-radius: 6px;
  border-color: rgb(88, 7, 163);
  color: white;
  &:focus, &:hover {
    background-color: $theme-color;
  }
}
.Secondary {
  background-color: $background-color;
  font-family: 'Fira Sans', sans-serif;
  color: rgb(88, 7, 163);
  //border: 1px solid $theme-color;
  border-style: solid;
  border-color: rgb(88, 7, 163);
  //border-radius: 6px;
  &:hover {
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  }
}
.Tertiary {
  background-color: rgb(233, 232, 240);
  font-family: 'Fira Sans', sans-serif;
  color: $theme-color;
  border: 1px solid hidden;
  letter-spacing: 0px;;
  border-radius: 0px;
  &:hover {
    background-color: white;
    // box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  }
}

.LevelBlob {
  color: rgb(88, 7, 163);

}

// Size Variables
.Mini {
  border-radius: 25px;
  padding: 0 15px;
  font-size: 9px;
  width: 65px;
}

.Small {
  min-height: 24px;
  font-size: 12px;
  //icon-size: 16px;
  padding: 0 8px;
  .IconBox {
    font-size: 16px;
  }
}
.Medium {
  min-height: 32px;
  font-size: 12px;
  padding: 0 16px;
  .IconBox {
    font-size: 16px;
  }
}
.Large {
  min-height: 48px;
  font-size: 14px;
  padding: 0 24px;
  .IconBox {
    font-size: 24px;
  }
}

//Icon Side Variables
.Left{
  .IconBox{
    padding-right:8px;
  }
}
.Right{
  .IconBox {
    padding-left:8px;
  }
}

.Round {
  background-color: $button-color;
  border-radius: 50%;
  border: none;
  color: $stdtext-color;
  margin: 0;
  padding: 0;
  transition: all 400ms ease-out;
  &.Mini{
    font-size: 12px;
    height: 30px;
    width: 30px;
  }
  &.Small{
    width: 40px;
    height: 40px;
    //border-radius: 38px;
    font-size:16px;
  }
  &.Medium{
    width: 52px;
    height: 52px;
    //border-radius: 50px;
    font-size:16px;
  }
  &.Large{
    width: 52px;
    height: 52px;
    //border-radius: 50px;
    font-size:24px;
  }
  &:hover {
    background-color: $theme-color-dark;
    color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  }
  &:focus {
    background-color: $theme-color-dark;
    color: #fff;
  }
}

.Group {
  &.Small{
    font-size: 12px;
    .IconBox {
      font-size: 16px;
    }
  }
  &.Medium{
    font-size: 12px;
    .IconBox {
      font-size: 16px;
    }
  }
  &.Large{
    font-size: 14px;
    .IconBox {
      font-size: 24px;
    }
  }
}

.Disabled, :disabled {
  background-color: #ededf0;
  border: none;
  color: #afafbb;
  pointer-events: none;
  &:hover {
    background-color: #ededf0;
    color: #afafbb;
  }
}
