@import '../../variables.scss';

$nav-color: rgba(45, 35, 91, 0.6);

a:link, a:active, a:hover, a:visited a:focus {
  outline:none;
}

.GrpNavItem {
  height: 68px;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  &:focus{
    outline:none;
  }

  &:hover > .IconBox {
    background-color: #ffffff;
    color: $heading-color;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
  }
  &:hover > .LabelBox {
    color: #fff;
  }
  &.ActiveNavItem > .LabelBox {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  &.ActiveNavItem > .IconBox {
    background-color: #fff;
    color: $theme-color;
    //box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
    }
  }
}

.IconBox {
  width: 52px;
  height: 52px;
  border-radius: 50px;
  background-color: $nav-color;
  color: #fff;
  //background-color: #fff;
  //color: $stdtext-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all 400ms ease-out;
}

.LabelBox {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  padding-left: 16px;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  transition: all 400ms ease-out;
  flex: 1;
  display: flex;
  transform: scale(0);
  animation-name: show;
  animation-delay: 400ms;
  animation-fill-mode: forwards;
  position: absolute;
}

@keyframes show {
  0% {
    position: absolute;
    transform: scale(0);
  }
  100% {
    position: relative;
    transform: scale(1);
  }
}

.Caret {
  font-size: 20px;
}

.Arrow {
  width: 0; 
  height: 0; 
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent; 
  border-right:5px solid #fff; 
  left: -5px;
  top: 10px;
  position: absolute;
}

.ListBox {
  :active {
    outline: none;
  }
  position: absolute;
  width: 200px;
  left: calc(100% + 10px);
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  top: 20px;
  padding: 5px;
  display: none;
  flex-direction: column;
  &.Show {
    display: flex;
    outline:none;
  }
}

.ListWrapper {
  height: 100%;
  width: 100%;
  border-left: 2px solid $heading-color;
  flex: 1;
  flex-direction: column;
  .Item {
    display: flex;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    align-items: center;
    font-size: 12px;
    color: $stdtext-color;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 400ms ease-out;
    box-sizing: border-box;
    &:hover {
      background-color: $heading-color;
      color: #fff;
    }
    &.ActiveSubItem > .GrpNavItem.LabelBox {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
    &.ActiveSubItem > .GrpNavItem.IconBox {
      background-color: #fff;
      color: $theme-color;
      box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
      &:hover {
        background-color: $heading-color;
        color: #fff;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
      }
    }
  }
}
