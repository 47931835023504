@import "./ag-theme-material-vars";
@import "./ag-theme-base";

.ag-theme-material {
  %selected-tab {
    border-bottom: 2px solid $primary-color;
  }
  
  @include ag-grid-theme($params);
  
  .ag-icon-checkbox-checked,
  .ag-icon-checkbox-checked-readonly,
  .ag-icon-checkbox-unchecked,
  .ag-icon-checkbox-unchecked-readonly,
  .ag-icon-checkbox-indeterminate,
  .ag-icon-checkbox-indeterminate-readonly,
  .ag-icon-radio-button-on,
  .ag-icon-radio-button-off {
    height: $icon-size + 6;
    width: $icon-size + 6;
    font-size: $icon-size + 6;
    line-height: $icon-size + 6;
  }
  
  .ag-header-cell, .ag-header-group-cell {
    transition: background-color 0.5s;
  }
  
  .ag-cell-highlight {
    background-color: $range-selection-highlight-color !important;
  }
  
  .ag-cell-highlight-animation {
    transition: background-color 1s;
  }
  
  .ag-menu-option {
    height: $grid-size * 5 - 2;
  }
  
  .ag-side-bar {
    border-bottom: 0;
    border-right: 0;
    border-top: 0;
    border-top-right-radius: 10px;
    
    .ag-side-buttons {
      border-top-right-radius: 10px;
    }
    .ag-side-buttons button {
      border: 0;
      color: $secondary-foreground-color;
      font-family: $secondary-font-family;
      font-size: $secondary-font-size;
      font-weight: $secondary-font-weight;
      background: transparent;
    }
    
    .ag-side-buttons .ag-side-button button {
      background-color: transparent;
      border-width: 0;
    }
    
    .ag-side-buttons .ag-selected button {
      border-left: 2px solid $primary-color;
      background-color: $tool-panel-background-color;
      margin-left: -2px;
      padding-left: 1px;
    }
    
    .ag-filter-toolpanel-body {
      background-color: $background-color;
    }
  }
  
  .ag-status-bar {
    border-top-width: 1px;
  }
  
  .ag-rtl .ag-side-bar {
    .ag-side-buttons .ag-selected button {
      border-left: 0;
      margin-left: 0;
      padding-left:0;
      
      border-right: 2px solid $primary-color;
      margin-right: -2px;
      padding-right: 1px;
    }
  }
}
