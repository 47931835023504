@import '../../../variables.scss';

.GrpCellEditor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
}

.Input {
  height: 100%;
  width: 80%;
  padding-left: 10px;
  border: none;
}

.ButtonBox {
  height: 100%;
  flex: 1;
}

.Btn {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border-left: 1px solid #e0e0e0;
  transition: all 300ms ease-out;
  &:first-child {
    border-bottom: 1px solid #e0e0e0;
  }
  &:hover {
    background-color: #f0f0f0;
  }
}

.Accept {
  color: $theme-color;
}

.Trash {
  color: #888;
}
