.Modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  img {
    width: 25%;
  }
}

.Title {
  margin-top: 15px;
  color: #fff;
  font-weight: 200;
  font-size: 1.2rem;
  user-select: none;
  cursor: default;
}

.ModalPanel {
  box-sizing: border-box;
  width: 100%;
  margin-top: 30px;
}

.SelectBox {
  height: 400px;
  width: 100%;
  overflow-y: scroll;
  background-color: #f7f7f7;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #e8e8e8;
}

.Client {
  height: 35px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border: 2px solid #ebebeb;
  transition: all 150ms ease-out;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    transform: translate(0, -3px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    border-color: #cecece;
  }
}

.Spinner {
  color: #693896;
}
