@import '../../variables.scss';

.GrpToaster {
  position: fixed;
  right: -430px;
  bottom:25px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, .2);
  width: 400px;
  transition: all 300ms ease-out;
  display: flex;
  cursor: pointer;
  &.Show {
    right: 25px;
  }
}

.IconBox {
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  position: absolute;
  display: flex;
  border-radius: 6px 0 0 6px;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  &.Error {
    background-color: rgb(216, 57, 57);
  }
  &.Success {
    background-color: rgb(57, 216, 70);
  }
  &.Info {
    background-color: $theme-color;
  }
}

.Body {
  flex: 1;
  padding: 15px 15px 15px 95px;
  box-sizing: border-box;
  font-size: 12px;
  min-height: 80px;
  width: 100%;
  word-wrap: break-word;
  line-height: 1.25;
  span {
    font-weight: bold;
    flex:1;
    word-wrap: break-word;
    width: 30px;
    color: rgb(150, 24, 24);
  }
}


.Close {
  position: absolute;
  top: 10px;
  right: 10px;
  user-select: none;
  cursor: pointer;
  font-size: 10px;
}
