@import '../../../variables.scss';

$button-font-weight: 400;
$button-font-size: 1.4rem;
$button-border-radius: 100px;

.GrpButtonGroup {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.GrpBGLeft {
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  border-radius: 100px 0 0 100px;
  border-right: 0.5px solid #380F6F;
  border-top: 1px solid transparent;
  margin: 0;
}

.GrpBGMid {
  border-bottom: 1px solid transparent;
  border-left: 0.5px solid #380F6F;
  border-radius: 0;
  border-right: 0.5px solid #380F6F;
  border-top: 1px solid transparent;
  margin: 0;
}

.GrpBGRight {
  border-bottom: 1px solid transparent;
  border-left: 0.5px solid #380F6F;
  border-radius: 0 100px 100px 0;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  margin: 0;
}
